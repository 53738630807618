import * as React from "react";
import { Avatar, Box, Button, FormHelperText, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import dotenv from "react-dotenv";
import WithMessages from "../../components/WithMessages";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { TextField } from "../../theme/vs-theme";
import { useTranslation } from "react-i18next";

const ProfilePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [invitations, setInvitations] = React.useState([]);
  const { t } = useTranslation();

  const [resetPasswordForm, setResetPasswordForm] = React.useState({
    oldPassword: "",
    newPassword: "",
    newPassword_confirmation: "",
  });

  React.useEffect(() => {
    if (user?.user?.invitations) {
      setInvitations(user?.user?.invitations);
    }
  }, [user, navigate, props]);

  const acceptInvitation = (signedRoute, email) => {
    const searchParams = new URLSearchParams(signedRoute);
    setLoading(true);
    const url = new URL(
      "/institutions/v1/medical-groups/enrollments",
      dotenv.API_URL
    );
    url.searchParams.set("email", email);
    url.searchParams.set(
      "medical_group_id",
      searchParams.get("medical_group_id")
    );
    url.searchParams.set("signature", searchParams.get("signature"));
    axios
      .post(
        url.toString(),
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        props.showMessage("Invitacion al equipo médico aceptada.", () =>
          navigate(0)
        );
        setLoading(false);
      })
      .catch((error) => {
        props.showMessage(
          "Invitacion al equipo médico rechazada. Mensaje:" +
            error.response.data?.message,
          () => navigate(0),
          "error"
        );
        setLoading(false);
      });
  };

  const handleChange = (evt) => {
    setResetPasswordForm({
      ...resetPasswordForm,
      [evt.target.name]: evt.target.value,
    });
  };

  const resetPassword = () => {
    let url = new URL("/institutions/v1/users/reset-password", dotenv.API_URL)
      .href;
    axios
      .post(url, resetPasswordForm, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        props.showMessage(response.data.message, () =>
          setResetPasswordForm({
            oldPassword: "",
            newPassword: "",
            newPassword_confirmation: "",
          })
        );
        setErrors({});
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          setErrors(error.response.data.errors);
        } else {
          props.showMessage(
            "Ha ocurrido un error al cambiar la contraseña",
            () => {},
            "error"
          );
        }
      });
  };

  return (
    <div>
      <Grid container alignContent="center" alignItems="center">
        <Grid width={"100%"}>
          <Avatar
            src={user?.user?.avatar?.url}
            sx={{
              margin: "0 auto",
              width: "80px",
              height: "80px",
              border: 4,
              backgroundColor: "#D9D9D9",
              borderColor: "white",
            }}
          />
          <InputLabel sx={{ textAlign: "center" }}>
            {user?.user?.name} {user?.user?.lastname}
          </InputLabel>
        </Grid>
        {props.role === "doctor" ? (
          <Grid xs={12} sx={{ mt: 4 }}>
            <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
              {t("pending_invitations_teams")}
              <br />
              <br />
              <Grid container direction="row" spacing={2}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("medical_group")}</TableCell>
                        <TableCell align="right">
                          {t("invitation_date")}
                        </TableCell>
                        <TableCell align="right">{t("actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitations.map((invitation) =>
                        invitation.status == "pending" ? (
                          <TableRow
                            key={invitation.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {invitation.medical_group.name}
                            </TableCell>
                            <TableCell align="right">
                              {moment(invitation.created_at).format("lll")}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  acceptInvitation(
                                    invitation.enroll_secure_link,
                                    invitation.email
                                  )
                                }
                              >
                                {t("accept_invitation")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Paper>
            <Paper
              sx={{ marginTop: "30px", backgroundColor: "white", padding: 4 }}
            >
              {t("change_password")}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <InputLabel>{t("current_password")}</InputLabel>
                  <TextField
                    name="oldPassword"
                    value={resetPasswordForm.oldPassword}
                    onChange={handleChange}
                    type="password"
                    sx={{ width: "90%" }}
                  ></TextField>
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.oldPassword && errors.oldPassword[0]}
                  </FormHelperText>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <InputLabel>{t("new_password")}</InputLabel>
                  <TextField
                    name="newPassword"
                    value={resetPasswordForm.newPassword}
                    onChange={handleChange}
                    type="password"
                    sx={{ width: "90%" }}
                  ></TextField>
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.newPassword && errors.newPassword[0]}
                  </FormHelperText>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <InputLabel>{t("confirm_password")}</InputLabel>
                  <TextField
                    name="newPassword_confirmation"
                    value={resetPasswordForm.newPassword_confirmation}
                    onChange={handleChange}
                    type="password"
                    sx={{ width: "90%" }}
                  ></TextField>
                </Box>
              </Box>
              <Button
                onClick={resetPassword}
                sx={{
                  color: "white",
                  background: "#3657D9",
                  marginTop: "20px",
                  width: "30%",
                }}
              >
                {t("change_password")}
              </Button>
            </Paper>
          </Grid>
        ) : null}
        <Backdrop
          sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </div>
  );
};

export default WithMessages(ProfilePage);
